<template>
  <div>
    <v-app-bar
      color="orange accent-3"
      dense
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>My LMS</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- @click="() => {}" -->
          <v-list-item
            v-for="n in 1"
            :key="n"
            @click="logout()"
          >
            <v-list-item-title>Logout</v-list-item-title>
            <!-- <v-list-item-title>Logout {{ n }}</v-list-item-title> -->
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name: 'MainNav',
  props: {

  },
  methods: {
    logout: function (){
      window.location.reload();
    }
  }
}
</script>

<style>

</style>