<template>
  <v-container class="grey lighten-5 backgrd fill-height">
    <v-row >
      <v-col cols="12">
        <v-card
          class="pa-2 cardTrans"
          flat
        >
          <!-- <p class="text-h7 text-center">{{ msg }}</p> -->
          <p class="text-h5 text-center">Launch the ACP exam in (3) three easy steps</p>
        </v-card>
      </v-col>      
    </v-row>
    <v-row>
      <v-col cols="4" class="d-flex flex-column align-end">
        <v-card
          class="pa-2 cardColor flex d-flex flex-column"
          outlined
          tile
          max-width="400px"
        >
          <v-card-text class="flex">
          <h3 class="mt-0">STEP 1: System Check</h3>
          <p class="text-left pa-2 mb-0">The ACP exam can only be accomplished in lockdown mode, requiring the installation of the ACP approved lockdown browser. 
            The Lockdown Browser will only function on machines with the minimum sytem requirements installed.</p>
          <ul class="text-left">
            <li>Windows: 10 and 8.</li>
            <li>Mac: MacOS 10.12 or higher.</li>
            <li>iOS: 11.0+ (iPad only). Must have a compatible LMS integration.</li>
          </ul>
          <p  class="text-left pl-2 mt-2 mb-0">Memory</p>
          <ul class="text-left">
            <li>Windows: 75 MB permanent space on the hard drive.</li>
            <li>Mac: 120 MB permanent space on the hard drive.</li>
          </ul>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="d-flex flex-column align-center">
        <v-card
          class="pa-2 cardColor  flex d-flex flex-column"
          outlined
          tile
          max-width="400px"
        >
          <v-card-text class="flex">
          <h3 class="mt-0">STEP 2: Download</h3>
          <p class="text-left pa-2">The ACP exam requires the pre-installation of the Respondus Lockdown Browser.</p>
          <p class="text-left pa-2">If the browser is installed, a new window should appear with the text "Your browser is installed and working properly."</p>
          <p class="text-left pa-2">If the browser is not installed, this browser will display an error message instead, such as "Firefox does not know how to open this page" or "The webpage cannot be displayed."</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" class="d-flex flex-column align-begin">
        <v-card
          class="pa-2 cardColor  flex d-flex flex-column"
          outlined
          tile
          max-width="400px"
        >
          <v-card-text class="flex">
          <h3 class="mt-0">STEP 3: Launch</h3>
          <p class="text-left pa-2">To launch the exam, you will be asked to enter your appointment eligibility number and your surname to enable the launch button.</p>
          <p class="text-left pa-2">Your eligibility number and surname can be located on your confirmation letter. After entering these details, you will be able to click the "launch" button to begin the exam.</p>
          </v-card-text>
        </v-card>
      </v-col>            
    </v-row>

    <v-row>
      <v-col >
        <v-dialog max-width="1000px">
          <template v-slot:activator="{ on, attrs }">

                <v-card class="pa-2 cardTrans" flat>
                  <v-btn 
                    color="green" 
                    dark
                    v-bind="attrs"
                    v-on="on">
                    Get started
                  </v-btn>
                </v-card>

          </template>
          <template v-slot:default="dialog">
            <!-- <v-row>
              <v-col>           -->
                <v-card class="pa-2 mx-auto" >
                  <v-card-text>
                    <v-stepper v-model="e1">
                      <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">
                          System Check
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="e1 > 2" step="2">
                          Download
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">
                          Launch
                        </v-stepper-step>
                      </v-stepper-header>

                      <v-stepper-items>
                            <v-stepper-content step="1">
                              <v-card
                                class="mb-8"
                                color="grey lighten-3"
                                height="260px"
                              >
                                <v-card-text>
                                  <v-row justify="center">
                                    <v-col cols="6">
                                      <v-btn
                                        color="primary"
                                        @click="systemcheck()"
                                      >
                                        Run System check
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                  <v-row justify="center" v-if="showprogress">
                                    <v-col cols="6">
                                      <v-progress-linear
                                        :color="progresscolor"
                                        :indeterminate="!showpass"
                                        height="25"
                                        
                                      ></v-progress-linear>
                                    </v-col>
                                  </v-row>
                                  <v-row justify="center" v-if="showpass">
                                    <v-col cols="6">
                                        <v-icon
                                          large
                                          color="green"
                                        >
                                        mdi-check-circle
                                        </v-icon>                                      
                                      System check passed
                                    </v-col>
                                  </v-row>                                  
                                </v-card-text>
                              </v-card>

                              <v-btn
                                class="ml-3"
                                color="primary"
                                @click="e1 = 2"
                                :disabled="btn_syscheck"
                              >
                                Next
                              </v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                              <v-card
                                class="mb-8"
                                color="grey lighten-3"
                                height="260px"
                              >
                                <v-card-text class="text-left">
                                  <!-- <p>To take the ACP test, your computer must have Respondus Locked Browser installed.  
                                    If you are not sure if it is installed, click the "Test for download" button below.</P> -->
                                  <v-row class="pt-2">
                                    <p>Click the "TEST BROWSER INSTALLATION" button below. If the LDB browser is installed, your browser may present you with a confirmation popup asking if you would like to continue. If it is not installed, nothing will happen, and you will need to download from one of the links below.</P>
                                  </v-row>                                  
                                  <v-row class="mb-2 mt-0" justify="center">
                                    <v-btn
                                      color="primary"
                                      @click="testdownload()"
                                    >
                                      Test Browser Installation
                                    </v-btn>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="6">
                                      <v-row align="center">
                                        <v-icon
                                          large
                                          color="blue darken-2"
                                        >
                                        mdi-windows
                                        </v-icon>
                                        <a href="../../assets/LockDownBrowserOEMSetup.exe" download>Download the Windows setup .exe file</a> 
                                      </v-row>
                                      <v-row>
                                        <ol>
                                          <li>"Open" or "Run" the exe file</li>
                                          <li>Follow the instructions in the InstallShield setup.</li>
                                          <li>Retest the "TEST" button above, and make sure the Respondus Browser launches.</li>
                                        </ol>                                  
                                      </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-row align="center">
                                        <v-icon
                                          large
                                          color="gray darken-2"
                                        >
                                          mdi-apple
                                        </v-icon>
                                        <a href="../../assets/InstallLDBOEM-SDK.zip" download>Download the Mac installer Zip</a> 
                                      </v-row>
                                      <v-row>
                                        <ol>
                                          <li>Extract the files and run.</li>
                                          <li>Answer the sercurity prompts and follow the instructions.</li>
                                          <li>Retest the "TEST" button above, and make sure the Respondus Browser launches.</li>
                                        </ol> 
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>

                              <v-btn
                                class="mr-3"
                                color="primary"
                                @click="e1 = 1"
                              >
                                Back
                              </v-btn>

                              <v-btn
                                class="ml-3"
                                color="primary"
                                @click="e1 = 3"
                                :disabled="btn_download"
                              >
                                Next
                              </v-btn>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                              <v-card
                                class="mb-8"
                                color="grey lighten-3"
                                height="260px"
                              >
                                <v-card-text>
                                  <v-form ref="launchForm" v-model="valid" lazy-validation>
                                    <v-row justify="center">
                                      <v-col cols="5">
                                      <v-text-field
                                        label="Last name"
                                        hint="The test taker's last name"
                                        v-model="lastname" :rules="[rules.required]" 
                                      ></v-text-field>
                                      </v-col>
                                    </v-row>                                         
                                    <v-row justify="center">
                                      <v-col cols="5">
                                      <v-text-field
                                        label="Eligibility"
                                        hint="From the confirmation email"
                                        v-model="eligibility" :rules="[rules.required]" 
                                      ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                      <v-col cols="5">
                                        <v-dialog v-model="modaldialog" max-width="300px">
                                          <template v-slot:activator="{ on, attrs }">

                                                <v-card class="pa-2 cardTrans" flat>
                                                  <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"                  
                                                    dark
                                                    color="green"
                                                    @click="validate()"
                                                    :disabled="!valid2" 
                                                  >
                                                    Launch the Exam
                                                  </v-btn>
                                                </v-card>

                                          </template>
                                          <template v-slot:default="dialog">
                                            <!-- <v-row>
                                              <v-col>           -->
                                                <v-card class="pa-2 mx-auto" >
                                                  <v-card-text>
                                                    <v-progress-circular
                                                      :size="70"
                                                      :width="7"
                                                      color="purple"
                                                      indeterminate
                                                    ></v-progress-circular>
                                                    <p class="pt-6">Please wait while your exam launches.</p>
                                                  </v-card-text>
                                                  <v-card-actions class="justify-end">
                                                    <v-btn
                                                      text
                                                      @click="dialog.value = false"
                                                    >Close</v-btn>
                                                  </v-card-actions>
                                                </v-card>
                                              <!-- </v-col>
                                            </v-row> -->
                                          </template>
                                        </v-dialog>
                                      </v-col>      
                                    </v-row>      
                                  </v-form>
                                </v-card-text>
                              </v-card>

                              <v-btn
                                class="mr-3"
                                color="primary"
                                @click="e1 = 2"
                              >
                                Back
                              </v-btn>

                            </v-stepper-content>
                          </v-stepper-items>

                    </v-stepper>

                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      text
                      @click="dialog.value = false;resetstepper()"
                    >Close</v-btn>
                  </v-card-actions>
                </v-card>
              <!-- </v-col>
            </v-row> -->
          </template>
        </v-dialog>
      </v-col>      
    </v-row>  



  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Home',
  props: {
    msg: String
  },
  data () {
      return {
        modaldialog: false,
        e1: 1,
        showprogress: false,
        showpass: false,
        progresscolor: "red accent-4",
        btn_syscheck: true,
        btn_download: true,
        btn_launch: true,
        valid: true,
        valid2: true,
        lastname: "",
        eligibility: "",
        rules: {
          required: value => !!value || "Required."
        },
        token: "",
        timer: ""     
      }
    },
  methods: {
    resetstepper: function (){
      this.e1 = 1,
      this.btn_syscheck = true,
      this.showprogress = false,
      this.showpass = false,
      this.progresscolor = 'red accent-4'
      clearInterval(this.timer)
    },
    systemcheck: function () {
      
      this.showprogress = true;

      this.timer = setInterval(() => {
        this.btn_syscheck = false;
        this.showpass = true;
        this.progresscolor = 'green';

      },2000);
    },
    validate() {
      if (this.$refs.launchForm.validate()) {
        // submit form to server/API here...
        this.launch()
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },    
    testdownload2: async function () {
      // this.btn_download = false;
			try {
				await this.followlink();
			} catch (error) {
				console.log("testdownload2 Method failed, failure message: "+ error);
			}      
    },
    followlink: async function () {
      window.location.href = 'ldb1:aa%7Bh0v5GXdPMK1PkRWs5Ist7lLZPKlk2QRJhY82NTK9U255VArR9jfbShgWK5EyWvkxfoSbJjGsSoJGHEy3QsO2JgFFAr1TzsVnapwHb6jTIC3rViC/ICuGJV8g1JjctXewh2vfmUks2VaNQNGVh/UHf6pBTVs/9Thc%7D';
    },
    testdownload: async function () {
      try {
        await this.followlink().then(this.btn_download = false).catch();

				// const navigationResult = await this.followlink();
        // if (navigationResult) {
          // not sure how to get true or false if able to launch test link
        // } else {
          //  this.btn_download = false;
        // }
			} catch (error) {
				console.log("testdownload Method failed, failure message: "+ error);
			}      
    },
    launch: function (){
      // await this.getToken();
      // alert ('token: ' + this.token);
      var self = this;
        self.getExam();          
    },
    // getToken: async function(){
    //   // console.log('Running getToken...')
    //   this.token = "";
    //   var self = this;
    //   await axios
    //     .get('.gee/get-token.php', {
    //     params: {
    //       // strSystemID: 'automation.user',
    //       // strPassword: 'Password1'
    //       strSystemID: 'GetYourOwn',
    //       strPassword: 'GetYourOwn'          
    //     }
    //   })
    //   .then(response => { // you get this far, the http_response_code is 200 or 503 
    //       //return the Token
    //       let resData=this.getXMLNode({xmlStr:response.data},'string');
    //       self.token = resData;
    //   })
    //   .catch(error => 
    //       {
    //         console.log("getToken Request failed, failure message: "+ error)
    //       }
    //   ); 
    // },
    getExam: async function(){
      var self = this;
      await axios
        .get('.ondemand/get-exam-url.php', {
        params: {
          EligibilityId: self.eligibility,
          Client: 'ACP',
          Program: 'ACP',
          Exam: 'PRTEM'
        }
      })
      .then(response => { // you get this far, the http_response_code is 200 or 503
      if (response.data.http == "success"){
        // console.log('http=sucesss');
        // console.log('response.data.data ' + decodeURIComponent(response.data.message));
        window.location = decodeURIComponent(response.data.message);
      } else {
            // console.log('http=error');
            // console.log('response.data.data ' + response.data.message);            
            let resData=this.getXMLNode({xmlStr:response.data.message},'response');
            alert(resData);
            // alert(response.data);
            self.modaldialog = false;
      }
          
      })
      .catch(error => 
          {
            let resData=this.getXMLNode({xmlStr:error.response.data.message},'response');
            alert(resData);
            self.modaldialog = false;
          }
      ); 
    },    
    getXMLNode: function(parm,nod){
          let str=parm.xmlStr;
          //Create document object
          let xmlDoc = new DOMParser().parseFromString(str, "text/xml");
          // Take out the content of the two latitude and longitude nodes in the node and convert the string to a string array
          let myString=xmlDoc.getElementsByTagName(nod)[0].innerHTML.split(" ");
          return myString;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: circle;
  padding: 10;
}
li {
  margin: 0 0px;
}
a {
  color: #42b983;
}
.backgrd {
  /* background: url('../../../public/assets/Test_Center_2.png') no-repeat center center fixed !important; */
  background-image: url('../../assets/Test_Center_2.png');
  background-size: cover;
}
.cardColor {
   background-color: rgba(255, 255, 255, 0.9) !important;
   border-color: white !important;
 }
 .cardTrans {
   background-color: rgba(255, 255, 255, 0) !important;
 }
</style>
