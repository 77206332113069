import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

var ip = location.host;

if (ip == 'localhost:8080') {
  axios.defaults.baseURL = "http://localhost/api"; //dev
} else if (ip == 'https://ldb-demo.graphicjamsa.com') {
  axios.defaults.baseURL = "/api"; //production
} else if (ip == 'ldb-demo.graphicjamsa.com') {
  axios.defaults.baseURL = "/api"; //production
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  vuetify,
}).$mount('#app')
