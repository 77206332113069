<template>
  <div id="app">
    <v-app id="inspire" top-fixed-navbar left-fixed-sidebar>
      <v-main>
        <MainNav></MainNav>
          <!-- <router-view ></router-view> -->
          <Login></Login>
          <Home msg="Welcome to the ACP exam launch portal."/>
      </v-main>
    </v-app>     
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
import Home from './components/Home';
import MainNav from './components/MainNav';
import Login from './components/Login';


export default {
  name: 'App',
  components: {
    MainNav,
    Login,
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
